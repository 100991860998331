import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // validate voucherCode
  validatedVoucherCode: { status: 0, payload: null },
  setValidatedVoucherCode: action((state, payload = null) => {
    state.validatedVoucherCode = payload
  }),
  postValidateVoucherCode: thunk(async (actions, data) => {
    actions.setValidatedVoucherCode({ status: 1, payload: null })

    const response = await fetch('/api/vouchers/validate_promo_code', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setValidatedVoucherCode({ status, payload })
  }),
  //   tag voucher to user
  tagVoucherToUser: { status: 0, payload: null },
  setTagVoucherToUser: action((state, payload = null) => {
    state.tagVoucherToUser = payload
  }),
  postTagVoucherToUser: thunk(async (actions, data) => {
    actions.setTagVoucherToUser({ status: 1, payload: null })

    const response = await fetch('/api/vouchers/tag_voucher', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setTagVoucherToUser({ status, payload })
  }),
  // user vouchers
  userVouchers: { status: 0, payload: null },
  setUserVouchers: action((state, payload = null) => {
    state.userVouchers = payload
  }),
  getUserVouchers: thunk(async (actions, data) => {
    actions.setUserVouchers({ status: 1, payload: null })

    const response = await fetch('/api/vouchers/get_user_vouchers', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setUserVouchers({ status, payload })
  }),
  getRewardState: { status: 0, payload: null },
  setGetRewardState: action((state, payload = null) => {
    state.getRewardState = payload
  }),
  postGetRewards: thunk(async (actions, data) => {
    actions.setGetRewardState({ status: 1, payload: null })

    const response = await fetch('/api/vouchers/get_reward', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setGetRewardState({ status, payload })
  }),
  checkRewardState: { status: 0, payload: null },
  setCheckRewardState: action((state, payload = null) => {
    state.checkRewardState = payload
  }),
  postCheckRewards: thunk(async (actions, data) => {
    actions.setCheckRewardState({ status: 1, payload: null })

    const response = await fetch('/api/vouchers/check_rewards', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setCheckRewardState({ status, payload })
  }),

  // global state for freebie item voucher detail
  freebieVoucherDetails: typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('freebieVoucherDetails')) : null,
  setFreebieVoucherDetails: action((state, payload = null) => {
    state.freebieVoucherDetails = payload
  }),
  // global state for voucher details (discount promo, percentage promo) only without cart
  cachedVoucherDetails: typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem('cachedVoucherDetails')) : null,
  setCachedVoucherDetails: action((state, payload = null) => {
    state.cachedVoucherDetails = payload
  })
}
