import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders, headers } from 'utils/api'
import { urlParams } from 'utils/url'

export default {
  // cards
  scCardsV2: { status: 0, payload: null },
  setScCardsV2: action((state, payload = null) => {
    state.scCardsV2 = payload
  }),
  getScCardsV2: thunk(async (actions, params) => {
    actions.setScCardsV2({ status: 1, payload: null })

    const response = await fetch(`/api/zeus_supercard/v1/cards/get_cards?${urlParams(params)}`, {
      method: 'GET',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setScCardsV2({ status, payload })
  }),
  // points
  scPointsV2: { status: 0, payload: null },
  setScPointsV2: action((state, payload = null) => {
    state.scPointsV2 = payload
  }),
  getScPointsV2: thunk(async (actions, params) => {
    actions.setScPointsV2({ status: 1, payload: null })

    const response = await fetch(`/api/zeus_supercard/v1/points/get_points?${urlParams(params)}`, {
      method: 'GET',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setScPointsV2({ status, payload })
  }),
  // validate supercard
  validatedScV2: { status: 0, payload: null },
  setValidatedScV2: action((state, payload = null) => {
    state.validatedScV2 = payload
  }),
  postValidateScV2: thunk(async (actions, data) => {
    actions.setValidatedScV2({ status: 1, payload: null })

    const response = await fetch('/api/zeus_supercard/v1/cards/validate_card', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setValidatedScV2({ status, payload })
  }),
  // register supercard
  registeredScV2: { status: 0, payload: null },
  setRegisteredScV2: action((state, payload = null) => {
    state.registeredScV2 = payload
  }),
  postRegisterScV2: thunk(async (actions, data) => {
    actions.setRegisteredScV2({ status: 1, payload: null })

    const response = await fetch('/api/zeus_supercard/v1/cards/register_card', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setRegisteredScV2({ status, payload })
  }),
  // latest active suuper card

  latestActiveSc: { status: 0, payload: null },
  setLatestActiveSc: action((state, payload = null) => {
    state.latestActiveSc = payload
  }),
  getLatestActiveSc: thunk(async (actions, params) => {
    actions.setLatestActiveSc({ status: 1, payload: null })

    const response = await fetch(`/api/zeus_supercard/v1/cards/get_latest_card?${urlParams(params)}`, {
      method: 'GET',
      headers: { ...headers.contentTypeJson }
    })

    const { status, payload } = await responseHandler(response)
    actions.setLatestActiveSc({ status, payload })
  }),

  bonanzaStatus: { status: 0, payload: null },
  setBonanzaStatus: action((state, payload = null) => {
    state.bonanzaStatus = payload
  }),
  getBonanzaStatus: thunk(async (actions, data) => {
    actions.setBonanzaStatus({ status: 1, payload: null })

    const response = await fetch(`/api/bonanza/v1/inquire_raffle_status/${data}`, {
      method: 'GET',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setBonanzaStatus({ status, payload })
  }),
  // points per card
  scPointsPerCard: { status: 0, payload: null },
  setScPointsPerCard: action((state, payload = null) => {
    state.scPointsPerCard = payload
  }),
  getScPointsPerCard: thunk(async (actions, params) => {
    actions.setScPointsPerCard({ status: 1, payload: null })

    const response = await fetch(`/api/zeus_supercard/v1/points/get_card_points?${urlParams(params)}`, {
      method: 'GET',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setScPointsPerCard({ status, payload })
  }),
  raffleEntriesBonanza: { status: 0, payload: null },
  setRaffleEntriesBonanza: action((state, payload = null) => {
    state.raffleEntriesBonanza = payload
  }),
  getRaffleEntriesBonanza: thunk(async (actions, data) => {
    actions.setRaffleEntriesBonanza({ status: 1, payload: null })

    const response = await fetch(`/api/bonanza/v1/inquire_raffle_status/${data}`, {
      method: 'GET',
      headers: { ...defaultHeaders }
    })
    const { status, payload } = await responseHandler(response)
    actions.setRaffleEntriesBonanza({ status, payload })
  }),
}
