// import clevertap from 'clevertap-web-sdk'
// import dynamic from 'next/dynamic'

// const clevertap = dynamic(() => import('clevertap-web-sdk'), {
//   ssr: false // since we are using services in client side
// })

export const initializeCleverTap = async () => {
  try {
    if (typeof window === 'undefined') return // Ensure it's client-side

    const clevertap = (await import('clevertap-web-sdk')).default

    clevertap.privacy.push({ optOut: false }) // User hasn't opted out
    clevertap.privacy.push({ useIP: false }) // IP sharing disabled
    clevertap.init(
      process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID,
      process.env.NEXT_PUBLIC_CLEVERTAP_REGION
    )
    clevertap.spa = true

    console.log('CleverTap initialized successfully.')
  } catch (error) {
    console.error('Error initializing CleverTap:', error)
  }
}

// Push some events or profile data
export const pushEvent = async (eventName, eventData) => {
  if (
    typeof window !== 'undefined' &&
    window.clevertap &&
    window.clevertap.event
  ) {
    const clevertap = (await import('clevertap-web-sdk')).default
    const filteredEventData = () => {
      return Object.fromEntries(
        Object.entries(eventData).filter(
          ([key, value]) => value !== '' && value !== null
        )
      )
    }

    try {
      eventData.source = 'Web'
      eventData.brand = 'periperi'
      clevertap.event.push(eventName, filteredEventData())
      console.log(
        `Event "${eventName}" pushed successfully with data:`,
        filteredEventData()
      )
    } catch (error) {
      console.error(`Error pushing event "${eventName}":`, error)
    }
  } else {
    console.warn(
      'Event push skipped: CleverTap not initialized or not in browser.'
    )
  }
}

export const pushProfile = async (profileData) => {
  if (
    typeof window !== 'undefined' &&
    window.clevertap &&
    window.clevertap.event
  ) {
    const clevertap = (await import('clevertap-web-sdk')).default

    try {
      const onUserLoginData = {
        ...profileData,
        Site: {
          ...profileData.Site,
          'MSG-sms': true,
          'MSG-push': true
        }
      }
      clevertap.onUserLogin.push(onUserLoginData)

      console.log('Profile data has been pushed')
    } catch (error) {
      console.error('Error pushing profile data: ', error)
    }
  } else {
    console.warn(
      'Profile data push skipped: CleverTap not initialized or not in browser.'
    )
  }
}

export const updateProfile = async (updatedUserProfile) => {
  if (
    typeof window !== 'undefined' &&
    window.clevertap &&
    window.clevertap.event
  ) {
    const clevertap = (await import('clevertap-web-sdk')).default

    try {
      clevertap.profile.push(updatedUserProfile)

      console.log('Updated profile data has been pushed:')
    } catch (error) {
      console.error('Error pushing profile data: ', error)
    }
  } else {
    console.warn(
      console.warn('Update profile data push skipped: CleverTap not initialized or not in browser.')
    )
  }
}

export const flattenOptions = (optionsArray, recipientId = 1) => {
  const groupedOptions = optionsArray.reduce((grouped, option) => {
    const name = option.name
    const optionValues = Array.isArray(option.option_values)
      ? option.option_values.map((value) => value.name || value.option_value_id)
      : [option.option_values.name || option.option_values.option_value_id]

    if (!grouped[name]) {
      grouped[name] = []
    }

    grouped[name].push(...optionValues)
    return grouped
  }, {})

  return Object.keys(groupedOptions).reduce((acc, name, index) => {
    const optionKey = `product_option_${index + 1}`
    const optionValue = `${name} - ${groupedOptions[name].join(' | ')}`
    acc[optionKey] = optionValue
    return acc
  }, {})
}

export const flattenOrderItems = (orderItems) => {
  return orderItems.reduce((acc, item, index) => {
    const productIndex = index + 1
    const categories = flattenArray(item.product_details.categories, 'name')

    acc[`product_category_${productIndex}`] = categories
    acc[`product_name_${productIndex}`] = item.product_details.name
    acc[`product_id_${productIndex}`] = item.product_details.sku
    acc[`quantity_${productIndex}`] = item.quantity

    return acc
  }, {})
}

export const flattenArray = (array, keyName) => {
  return array.map((obj) => obj[keyName]).join(' | ')
}

// export const requestNotificationPermission = async () => {
//   if (typeof window === 'undefined') return

//   if (!('Notification' in window)) {
//     console.log('This browser does not support notifications.')
//     return
//   }

//   try {
//     const permission = await window.Notification.requestPermission()
//     console.log('PERMISSION', permission)
//     if (permission === 'granted') {
//       console.log('Notification permission granted')
//     } else if (permission === 'denied') {
//       console.log('Notification permission denied')
//     }
//   } catch (error) {
//     console.error('Error requesting notification permission:', error)
//   }
// }

export const requestNotificationPermission = async () => {
  if (typeof window === 'undefined') return

  const clevertap = (await import('clevertap-web-sdk')).default

  if (!('Notification' in window)) {
    console.log('This browser does not support notifications.')
    return
  }

  try {
    const permission = await window.Notification.requestPermission()
    console.log('PERMISSION', permission)
    if (permission === 'granted') {
      console.log('Notification permission granted')

      if (clevertap) {
        clevertap.notifications.push({
          titleText: 'Would you like to receive push notifications?',
          bodyText: 'We promise to only send you relevant content and give you updates on your transactions',
          okButtonText: 'Yes',
          rejectButtonText: 'No',
          okButtonColor: '#F28046',
          serviceWorkerPath: '/clevertap_sw.js'
        })
      }
    } else if (permission === 'denied') {
      console.log('Notification permission denied')
    }
  } catch (error) {
    console.error('Error requesting notification permission:', error)
  }
}
